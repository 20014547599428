var dd = {
    content: [{
        columns: [{
            text: 'logo goes here\ncreative brief description goes here'
        },
            {
                width: 200,
                columns: [{
                    text: 'Customer Team Name\nName goes here'
                },
                    {
                        width: 70,
                        text: 'Date\n12/12/12'
                    }
                ]
            }
        ]
    },
        '\n',
        {
            columns: [{
                table: {
                    width: ['auto', 'auto'],
                    body: [
                        [{
                            border: [false, true, false, false],
                            text: 'Overview',
                            colSpan: 2
                        },
                            {

                            }
                        ],
                        [{
                            border: [false, false, false, false],
                            text: 'Corridor Quote goes here..'
                        },
                            {
                                border: [false, false, false, false],
                                text: [{
                                    text: 'Approved Brands\n'
                                },
                                    {
                                        text: 'Approved brands image goes here'
                                    }
                                ]
                            }
                        ],
                        [{
                            colSpan: 2,
                            border: [false, false, false, false],
                            table: {
                                body: [
                                    ['Shopper', 'Occasions', 'Trip', 'Recommended Channels'],
                                    ['image', 'images', 'image', 'images']
                                ]
                            },
                            layout: {
                                defaultBorder: false
                            }
                        }]
                    ]
                }
            },
                {
                    width: 200,
                    text: 'merchandiser image goes here'
                }

            ]
        },
        '\n',
        {
            columns: [{
                table: {
                    width: ['auto', 'auto', 'auto'],
                    body: [
                        [{
                            border: [false, true, false, false],
                            text: 'Key Visuals',
                            colSpan: 3
                        },
                            {},
                            {}
                        ],
                        [{
                            border: [false, false, false, false],
                            text: 'Key Visual Image'
                        },
                            {
                                border: [false, false, false, false],
                                text: 'Key Visual Image'
                            },
                            {
                                border: [false, false, false, false],
                                text: 'Key Visual Image'
                            }
                        ]
                    ]
                }
            },
                {
                    width: 200,
                    table: {
                        width: ['auto', 'auto'],
                        body: [
                            [{
                                border: [false, true, false, false],
                                text: 'Selected Tagline'
                            }],
                            [{
                                border: [false, false, false, false],
                                text: 'Selected Tagline Image'
                            }]
                        ]
                    }
                }
            ]
        },
        '\n',
        {
            columns: [{
                width: 250,
                table: {
                    width: ['auto'],
                    body: [
                        [{
                            border: [false, true, false, false],
                            text: 'Digital Elements',

                        }],
                        [{
                            border: [false, false, false, false],
                            text: 'Digital Elements Image'
                        }]
                    ]
                }
            },
                {
                    width: '*',
                    table: {
                        width: ['auto'],
                        body: [
                            [{
                                border: [false, true, false, false],
                                text: 'Agency Partners'
                            }],
                            [{
                                border: [false, false, false, false],
                                table: {
                                    body: [
                                        ['image', 'Planet Studio', 'image', 'Other Agency'],

                                    ]
                                },
                                layout: {
                                    defaultBorder: false
                                }
                            }]
                        ]
                    }
                }
            ]
        },
        '\n',
        {
            text: [
                {
                    text: 'Agency Task'
                },
                {
                    text: 'Agency Task'
                }
            ]
        }
    ]
}