var WeatherWidget = function() {
    var self = this;

    // Openweather API
    //var apiBaseURL = "//api.openweathermap.org/data/2.5/weather?";
    //var apiKey = "6944465d456f2c73e2277650a4a02b52";

    var apiBaseURL = "//api.apixu.com/v1/current.json?";
    var apiKey = "3550c02aad8940978f9181953171207";

    var lat = 33.75;
    var lng = -84.39;

    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    this.bindEvents = function() {
        $("#widget-reset").on("click", function (e) {
            e.preventDefault();
            self.getLocation();
        })
    }

    this.init = function() {
        var d = new Date();
        $(".current-month").html(monthNames[d.getMonth()]);
        $(".current-day").html(d.getDate());

        this.bindEvents();
        this.getLocation();
    };

    this.getLocation = function() {

        if (Cookies.get('ko-hydration-coords')) {
            var cookieData = decodeURIComponent(Cookies.get('ko-hydration-coords')).split(",");

            lat = cookieData[0];
            lng = cookieData[1];

            self.getWeather();
        } else {
            if (navigator.geolocation) {
                var loc_timeout = setTimeout(this.getWeather, 7000);

                navigator.geolocation.getCurrentPosition(function(position) {
                    clearTimeout(loc_timeout);

                    lat = position.coords.latitude;
                    lng = position.coords.longitude;

                    var cookieData = lat + "," + lng;
                    Cookies.set('ko-hydration-coords', cookieData,  { expires: 1 });

                    self.getWeather();
                });
            } else {
                self.getWeather();
            }
        }
    };

    this.getWeatherIcon = function(defaultIcon) {
        // if icon is clear sky
        if(defaultIcon == '01d' || defaultIcon == '01n') {
            iconName = 'clear';
        }

        // if icon is clouds
        if(defaultIcon == '02d' || defaultIcon == '02n' || defaultIcon == '03d' || defaultIcon == '03n' || defaultIcon == '04d' || defaultIcon == '04n') {
            iconName = 'clouds';
        }

        // if icon is rain
        if(defaultIcon == '09d' || defaultIcon == '09n' || defaultIcon == '10d' || defaultIcon == '10n') {
            iconName = 'shower';
        }

        // if icon is thunderstorm
        if(defaultIcon == '11d' || defaultIcon == '11n') {
            iconName = 'storm';
        }

        // if icon is snow
        if(defaultIcon == '13d' || defaultIcon == '13n') {
            iconName = 'snow';
        }

        // if icon is mist
        if(defaultIcon == '50d' || defaultIcon == '50n') {
            iconName = 'mist';
        }

        $("#condition-icon").attr('src', 'images/weather/conditions/' + iconName + '.png');
    }

    this.getWeather = function() {

        // Openweather API URL
        // var apiURL = apiBaseURL + "lat="+lat+"&lon="+lng+"&appID=" + apiKey;

        var apiURL = apiBaseURL + "key="+apiKey+"&q="+lat+"," + lng;

        $.ajax({
            type: 'GET',
            url: apiURL,

            success: function(data) {
                //var temperature = Math.round(((data.main.temp - 273.15) * 1.8) + 32) + '°';

                //$(".weather-widget__current-temp").html(temperature);

                //$(".current-conditions").html(data.weather[0].main);
                //$(".current-location").html(data.name+", " + data.sys.country);


                //$(".weather-widget__wind-icon").html('<img src="images/weather/wind-speed.png" alt="Wind Speed">');
                //$(".weather-widget__wind-data").html(Math.round(data.wind.speed * 2.22369) + " MPH");

                //$(".weather-widget__air-pressure-icon").html('<img src="images/weather/humidity.png" alt="Humidity">')
                //$(".weather-widget__air-pressure-data").html(Math.round((data.main.pressure * 0.02953)*100)/100 + '"');

                //$(".weather-widget__humidity-icon").html('<img src="images/weather/air-pressure.png" alt="Air Pressure">');
                //$(".weather-widget__humidity-data").html(data.main.humidity + "%");

                //self.getWeatherIcon(data.weather[0].icon);

                var temperature = Math.round(data.current.temp_f) + '°';

                $(".weather-widget__current-temp").html(temperature);

                $(".current-conditions").html(data.current.condition.text);
                $(".current-location").html(data.location.name+", US");

                $(".weather-widget__wind-icon").html('<img src="images/weather/wind-speed.png" alt="Wind Speed">');
                $(".weather-widget__wind-data").html(Math.round(data.current.wind_mph) + " MPH");

                $(".weather-widget__air-pressure-icon").html('<img src="images/weather/air-pressure.png" alt="Air Pressure">')
                $(".weather-widget__air-pressure-data").html(data.current.pressure_in + '"');

                $(".weather-widget__humidity-icon").html('<img src="images/weather/humidity.png" alt="Humidity">');
                $(".weather-widget__humidity-data").html(data.current.humidity + "%");

                $("#condition-icon").attr('src', data.current.condition.icon);

                //self.getWeatherIcon(data.current.condition.icon);
            },

            error: function(jqXHR, textStatus, errorThrown) {
                // run error callback
            }
        });
    }

    this.init();
};