var SlideShow = function() {
    var self = this;

    this.init = function() {
        var fadeDuration = 3000;
        var slideDuration = 10000;

        $('.slide-show img:gt(0)').hide();

        setInterval(function(){
            $('.slide-show :first-child').fadeOut(fadeDuration)
                .next('img').fadeIn(fadeDuration)
                .end().appendTo('.slide-show');},
            slideDuration);
    };

    this.init();
};