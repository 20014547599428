var PDFExport = function() {
    var self = this;
    var imageDictionary ={};

    var PDFFontFamily = {
        proximaNova: {
            normal: 'proximanova-regular-webfont.ttf',
            bold: 'proximanova-bold-webfont.ttf',
            italics: 'proximanova-regularitalic-webfont.ttf',
            bolditalics: 'proximanova-bolditalic-webfont.ttf'
        }
    }

    var docDef = {
        content: [],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                color: '#306aae'
            },
            bigger: {
                fontSize: 15,
                bold: true
            }
        },
        defaultStyle: {
            font: 'proximaNova',
            columnGap: 20
        }
    }

    function convertImgToDataURLviaCanvas(url, callback, outputFormat) {
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function() {
            var canvas = document.createElement('CANVAS');
            var ctx = canvas.getContext('2d');
            var dataURL;
            canvas.height = this.height;
            canvas.width = this.width;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            callback(dataURL);
            canvas = null;
        };
        img.src = url;
    }

    this.bindEvents = function() {
        $("#download-pdf-link").click(function(e) {
            e.preventDefault();

            //var headerImage = {};

            //var textHeader = {
            //    text: "\nSample header\n",
            //    style: 'bigger',
            //    color: '#306aae'
            //};

            //var textHeader2 = {
            //    text: "Some other header\n",
            //    style: 'bigger',
            //    color: '#306aae'
            //};

            //var text = 'Sriracha tumblr forage shabby chic schlitz disrupt fanny pack tilde polaroid coloring book tbh humblebrag. Slow-carb four dollar toast bespoke polaroid health goth leggings fingerstache, man bun cray migas ugh narwhal paleo tote bag. Trust fund organic skateboard, truffaut austin tofu retro food truck. Microdosing meggings VHS gochujang humblebrag. Post-ironic schlitz semiotics, thundercats truffaut gluten-free sriracha +1 distillery tofu XOXO knausgaard etsy. Typewriter 8-bit pabst salvia. Asymmetrical messenger bag photo booth freegan, twee gentrify air plant.\n\n';
            //var text2 = 'Food truck keffiyeh woke, godard street art air plant before they sold out hammock photo booth chartreuse VHS readymade. Tote bag typewriter squid art party unicorn occupy. Twee activated charcoal cred, tumeric bicycle rights taxidermy literally. Asymmetrical flexitarian art party quinoa put a bird on it yr. Vice plaid af, messenger bag air plant banjo tbh. Post-ironic pickled try-hard fam air plant vice. Fam actually pok pok, woke cronut cred dreamcatcher hot chicken whatever roof party affogato.\n\n';

            //var imageURL= "http://ko-hydration.planetstudio.com/images/pdf-header.png";

            //var imageURL2= "http://ko-hydration.planetstudio.com/images/pdf-header.png";

            //var blankLine = "\n\n";

            //convertImgToDataURLviaCanvas(imageURL, function(base64Img){

            //console.log('IMAGE:',base64Img);

            //    headerImage.image = base64Img;
            //    headerImage.width = 220;
            //    docDef.content.push(headerImage);
            //    docDef.content.push(textHeader);
             //   docDef.content.push(text);
            //    docDef.content.push(textHeader2);
            //    docDef.content.push(text2);

             //   pdfMake.fonts = PDFFontFamily;
            //    pdfMake.createPdf(docDef).download('foo.pdf');
            // });

            $(".instructions-header").hide();
            $(".download-pdf").hide();
            $(".pdf-header").show();
            $(".review-pdf-title").show();

            html2canvas(document.getElementById("step6")).then(function(canvas) {

                //document.body.appendChild(canvas);

                var base64image = canvas.toDataURL("image/png");
                var pdfImage = {};

                pdfImage.image = base64image;
                pdfImage.width = 470;
                docDef.content.push(pdfImage);

                pdfMake.fonts = PDFFontFamily;
                pdfMake.createPdf(docDef).download('Hydration Creative Brief.pdf');

                $(".instructions-header").show();
                $(".download-pdf").show();
                $(".pdf-header").hide();
                $(".review-pdf-title").hide();
            });
        });
    };

    this.init = function() {

        this.bindEvents();
    };

    this.init();
};