var DidYouKnowWidget = function() {
    var self = this;

    this.init = function() {
        var fadeDuration = 1000;
        var slideDuration = 8000;

        $('.did-you-know-widget img:gt(0)').hide();

        setInterval(function(){
            $('.did-you-know-widget :first-child').fadeOut(fadeDuration)
                .next('img').fadeIn(fadeDuration)
                .end().appendTo('.did-you-know-widget');},
            slideDuration);
    };

    this.init();
};