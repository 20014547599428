var CalendarWidget = function() {
    var self = this;

    this.init = function() {
        var fadeDuration = 750;
        var slideDuration = 10000;

        //$('.calendar-widget img:gt(0)').hide();
        $('.calendar-widget img').hide();

        var images =$(".calendar-widget").children();

        while (images.length) {
            $(".calendar-widget").append(images.splice(Math.floor(Math.random() * images.length), 1)[0]);
        }

        $('.calendar-widget img:lt(1)').show();

        setInterval(function(){
            $('.calendar-widget :first-child').fadeOut(fadeDuration)
                .next('img').fadeIn(fadeDuration)
                .end().appendTo('.calendar-widget');},
            slideDuration);
    };

    this.init();
};