$(document).ready(function() {

    if ($(".home-content").length > 0) {
        var slideShow = new SlideShow();
        var weatherWidget = new WeatherWidget();
        var didYouKnowWidget = new DidYouKnowWidget();
        var calendarWidget = new CalendarWidget();
        var executionGuidelines = new ExecutionGuidelines();
    }

    if ($(".channel-data-container").length > 0) {
        var channelData = new ChannelData();
    }

    if ($(".build-content-wrapper").length > 0) {
        var customVisual = new CustomSolution();
        var pdfExport = new PDFExport();
    }

    $(".hover").on("mouseenter", function() {
        var $this = $(this),
            src = $this.attr('src').replace('off', 'on');
        $this.attr('src', src);

    });

    $(".hover").on("mouseleave", function() {
        var $this = $(this),
            src = $this.attr('src').replace('on', 'off');
        $this.attr('src', src);
    });

    $(".inactive-link").click(function(e) {
        e.preventDefault();
    })

    // Review Agency Task Text Area Counter
    /////////////////////////////////////////////////////////////////////

    if ($("#step6").length > 0) {
        var limit = 5;
        var textarea = document.getElementById("agency-task");
        var spaces = textarea.getAttribute("cols");

        textarea.onkeyup = function () {
            var lines = textarea.value.split("\n");

            for (var i = 0; i < lines.length; i++) {
                if (lines[i].length <= spaces) continue;
                var j = 0;

                var space = spaces;

                while (j++ <= spaces) {
                    if (lines[i].charAt(j) === " ") space = j;
                }
                lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
                lines[i] = lines[i].substring(0, space);
            }
            if (lines.length > limit) {
                textarea.style.color = 'red';
                setTimeout(function () {
                    textarea.style.color = '';
                }, 500);
            }

            textarea.value = lines.slice(0, limit).join("\n");
        };
    }

});