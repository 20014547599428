var CustomSolution = function() {
    var self = this;
    var baseURL = "build-a-custom-solution.html";
    var buildParams = {};
    var corridor, tagline, keyvisuals, shop = null;
    var selectedImages = 0;


    var corridorTitles = {
        "beauty" : "Beauty",
        "wellbeing" : "Wellbeing",
        "fitness" : "Fitness for Life",
        "sports" : "Sports"
    }

    $.extend({
        getQueryParameters : function(str) {
            return (str || document.location.search).replace(/(^\?)/,'').split("&").map(function(n){return n = n.split("="),this[n[0]] = n[1],this}.bind({}))[0];
        }
    });

    this.buildProgressBar = function () {
        var navImages = $(".progress-indicator img");
        var adjustedStep = this.buildParams.step - 1;

        $.each(navImages, function(i, val) {
            if(i == adjustedStep) {
                var $this = $(val),
                    src = $this.attr('src').replace('off', 'on');
                $this.attr('src', src);
            }

            if(i < adjustedStep) {
                var $this = $(val),
                    src = $this.attr('src').replace('off', 'done');
                $this.attr('src', src);
            }
        });
    };

    this.setTitle = function() {
        if (self.corridor != null) {
            var title;

            $.each(corridorTitles, function(i,v) {
                if(i === self.corridor) {
                   title = v;
                }
            });

            if(self.subcategory) {
                $.each(subcategoryTitles, function(i,v) {
                    if(i === self.subcategory) {
                        title += " / " + v;
                    }
                });
            }

            $("#step-title").text(title);
        } else {
            $("#step-title").text("Select a corridor and hit Next to continue");
        }
    }

    this.setReviewTitle = function() {
        if (self.corridor != null) {
            var title;

            $.each(corridorTitles, function(i,v) {
                if(i === self.corridor) {
                    title = v;
                }
            });

            if(self.subcategory) {
                $.each(subcategoryTitles, function(i,v) {
                    if(i === self.subcategory) {
                        title += " / " + v;
                    }
                });
            }

            $("#review-title").text(title);
        } else {
            $("#review-title").text("Select a corridor and hit Next to continue");
        }
    }

    this.updateStepContent = function() {
        $("#step" + this.buildParams.step).show();
    };

    this.updateBackNav = function() {
        var src;
        var step = parseInt(this.buildParams.step) - 1;
        var backURL = baseURL + "?step=" + step;

        if (step == 1) {
            $("#back").attr("href", backURL);

        } else if (step == 2) {
            backURL += "&corridor=" + self.corridor;
            $("#back").attr("href", backURL);

        } else if (step == 3) {
            backURL += "&corridor=" + self.corridor;
            $("#back").attr("href", backURL);


        } else if (step == 4) {
            backURL += "&corridor=" + self.corridor;
            backURL += "&subcategory=" + self.subcategory;

            $("#back").attr("href", backURL);

        } else if (step == 5) {
            backURL += "&corridor=" + self.corridor;
            backURL += "&subcategory=" + self.subcategory;
            backURL += "&keyvisuals" + self.keyvisuals;

            $("#back").attr("href", backURL);

        } else if (step == 6) {
            backURL += "&corridor=" + self.corridor;
            backURL += "&subcategory=" + self.subcategory;
            backURL += "&keyvisuals" + self.keyvisuals;
            backURL += "&tagline=" + self.tagline;

            $("#back").attr("href", backURL);

        }

        src = $("#back-img").attr('src').replace('-off', '-on');
        $("#back-img").attr('src', src);
    }

    this.updateNextNav = function() {
        var src;

        var nextURL = baseURL + "?step=" + (parseInt(this.buildParams.step) + 1);

        if (self.corridor) {
            nextURL += "&corridor=" + self.corridor;
        }

        if (self.subcategory) {
            nextURL += "&subcategory=" + self.subcategory;
        }

        if (self.keyvisuals) {
            nextURL += "&keyvisuals=" + self.keyvisuals;
        }

        if (self.tagline) {
            nextURL += "&tagline=" + self.tagline;
        }

        if (self.shop) {
            nextURL += "&shop=" + self.shop;
        }

        if (parseInt(this.buildParams.step) == 1) {
            $("#next").attr("href", nextURL);

            src = $("#next-img").attr('src').replace('-off', '-on');
            $("#next-img").attr('src', src);

        } else if (parseInt(this.buildParams.step) == 2) {
            $("#next").attr("href", nextURL);

            src = $("#next-img").attr('src').replace('-off', '-on');
            $("#next-img").attr('src', src);

        } else if (parseInt(this.buildParams.step) == 6) {
            $("#next").fadeTo(0,0).attr("href", "");

        } else {
            $("#next").attr("href", nextURL);

            src = $("#next-img").attr('src').replace('-off', '-on');
            $("#next-img").attr('src', src);

        }
    }

    this.updateStepController = function() {
        if (parseInt(this.buildParams.step) == 1) {
            $("#back").fadeTo(0,0).attr("href", "");

            self.corridor, self.subcategory, self.keyvisuals, self.tagline, self.shop = null
            this.setTitle();
        }

        if (parseInt(this.buildParams.step) == 2) {
            var partialURL = "partials/visual-guidelines-" + self.corridor + ".html";
            $("#step2").load(partialURL);

            this.setTitle();
            this.updateBackNav();
            this.updateNextNav();
        }

        if (parseInt(this.buildParams.step) == 3) {
            var partialURL = "partials/keyvisuals-" + self.corridor + ".html";
            $("#step3").load(partialURL);

            this.setTitle();
            this.updateBackNav();
        }

        if (parseInt(this.buildParams.step) == 4) {
            var partialURL = "partials/taglines-" + self.corridor + ".html";
            $("#step4").load(partialURL);

            this.setTitle();
            this.updateBackNav();
        }

        if (parseInt(this.buildParams.step) == 5) {
            var partialURL = "partials/pre-post-shop-" + self.corridor + ".html";
            $("#step5").load(partialURL);

            this.setTitle();
            this.updateNextNav();
            this.updateBackNav();
        }

        if (parseInt(this.buildParams.step) == 6) {
            var partialURL = "partials/review-overview-" + self.corridor + ".html";
            $(".review-overview").load(partialURL);

            if (self.corridor) {
                $("#review-approved-brands").attr('src', "images/build/approved-brands-" + self.corridor + ".png");
                $("#review-digital-platform").attr('src', "images/build/digital-platform-" + self.corridor + ".png");
                $("#merchandiser-image").attr('src', "images/build/merchandiser-" + self.corridor + ".jpg");
            }

            $(".review-pdf-title").hide();
            $(".pdf-header").hide();

            if (self.keyvisuals) {
                var keyVisuals = decodeURIComponent(self.keyvisuals).split(",");
                var html = "";

                $.each(keyVisuals, function(i, v){
                    var image = v.split("|");

                    html += '<div class="review-key-visual">';
                    html += '<img src="images/' + image[0] + '"></a>';
                    html += '<div class="key-visual__label">' + image[1] + '</div></div>';

                    $(".review-key-visuals").html(html);
                })
            }

            if (self.tagline) {
               $("#review-tagline").attr('src', decodeURIComponent(self.tagline));
            }

            this.setTitle();
            this.setReviewTitle();
            this.updateNextNav();
            this.updateBackNav();
        }
    }


    this.bindEvents = function() {

        $("a").click(function(e) {
           if ($(this).attr("href") == "#") {
               e.preventDefault();
           }
        });

        //STEP ONE
        ///////////////////////////////////////////////////
        $(".corridor-selection").on("click", function(e) {
            e.preventDefault();

            $(".corridor-selection img").each(function() {
                var $this = $(this),
                    src = $this.attr('src').replace('-on', '-off');
                $this.attr('src', src);
            });

            $("img", this).each(function() {
                var $this = $(this),
                    src = $this.attr('src').replace('-off', '-on');
                $this.attr('src', src);
            });

            self.corridor = $(this).attr("id");

            self.updateNextNav();
        });

        //STEP THREE
        ///////////////////////////////////////////////////
        $(document).on("click", ".custom-key-visual-select-btn", function(e) {
            e.preventDefault();

            $(".custom-key-visual-select-btn").each(function() {
                var $this = $(this),
                    src = $this.attr('src').replace('-on', '-off');
                $this.attr('src', src);
            });

            var $this = $(this),
                src = $this.attr('src').replace('-off', '-on');
            $this.attr('src', src);

            var gallery = $(this).data("gallery");

            var keyVisuals = [];

            //if (self.keyvisuals) {
            //    keyVisuals = decodeURIComponent(self.keyvisuals).split(",");
            //}

            $("." + gallery + " a").each(function() {
                var keyVisual = $(this).data('thumb');
                var imageName = $(this).data("caption")

                keyVisuals.push(keyVisual + "|" + imageName);

            });

            self.keyvisuals = encodeURIComponent(keyVisuals.join());

            self.updateNextNav();
        });

        //STEP FOUR
        ///////////////////////////////////////////////////
        $(document).on("click", ".tagline-selection", function(e) {
            e.preventDefault();

            $(".tagline-selection img").each(function() {
                var $this = $(this),
                    src = $this.attr('src').replace('-on', '-off');
                $this.attr('src', src);
            });

            $("img", this).each(function() {
                var $this = $(this),
                    src = $this.attr('src').replace('-off', '-on');
                $this.attr('src', src);
            });

            self.tagline = encodeURIComponent($(this).find('img:first').attr("src"));

            self.updateNextNav();
        });

    };

    this.init = function() {
        this.bindEvents();
        this.selectedImages = 0;

        if (document.location.search.length == 0) {
            window.location = baseURL + '?step=1';
        } else {
            this.buildParams = $.getQueryParameters();

            if (this.buildParams.step > 6) {
                window.location = baseURL + '?step=1'
            }

            $(".step").hide();

            this.corridor = this.buildParams.corridor;
            this.keyvisuals = this.buildParams.keyvisuals;
            this.tagline = this.buildParams.tagline;
            this.shop = this.buildParams.shop;

            this.buildProgressBar();
            this.updateStepContent();
            this.updateStepController();
        }
    };

    this.init();
};