var ExecutionGuidelines = function() {
    var self = this;
    this.isOpen = true;
    var closeTimeout;

    this.bindEvents = function() {
        $("#execution-guidelines-button").on("click", function(e) {
            e.preventDefault();
            clearTimeout(closeTimeout);

            if(self.isOpen) {
                self.close();
            } else {
                self.open();
            }
        });

        $(".execution-guidelines__learn-more").on("click", function(e) {
            e.preventDefault();
            clearTimeout(closeTimeout);
        });

        $(".execution-guidelines__download").on("click", function(e) {
            clearTimeout(closeTimeout);
        });
    };

    this.close = function() {
        self.isOpen = false;
        $(".execution-guidelines").animate({ marginLeft: '-=738px'}, 500, function () {
            $("#execution-guidelines-button-icon").addClass("button-closed");
        });
    };

    this.open = function() {
        self.isOpen = true;
        $(".execution-guidelines").animate({ marginLeft: '+=738px'}, 500, function() {
            $("#execution-guidelines-button-icon").removeClass("button-closed");
        });
    };

    this.init = function() {
        closeTimeout = setTimeout(self.close, 4000);
        this.bindEvents();
    };

    this.init();
};