var ChannelData = function() {
    var self = this;
    var activeTab = "merchandisers";

    this.bindEvents = function() {
        $(".channels-masthead__tabs li").on("click", function(e) {
            e.preventDefault();

            if($(this).attr("id") !== activeTab) {
                activeTab = $(this).attr("id");
                $(".channels-masthead__tabs li").removeClass('active');
                $(this).addClass('active');

                if (activeTab == "merchandisers") {
                    $(".merchandisers").show();
                    $(".channel-point-of-sale").hide();
                } else if (activeTab == "point-of-sale") {
                    $(".merchandisers").hide();
                    $(".channel-point-of-sale").show();
                }
            }
        });
    };

    this.init = function() {
        $(".channel-point-of-sale").hide();
        this.bindEvents();
    };

    this.init();
};